import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button,  PopoverBody, UncontrolledPopover } from "reactstrap";
import { getAcademicList, getAcademicScore, getAcademicYears, addAcademicYears, deleteAcademicYears, editAcademicYears, getRosterClassByAcademicYear } from '../../../Actions/AcademicAction/academicAction';
import { getUserSettingRequest } from 'Actions/SettingAction/settingAction';
import UpgradePopUp from '../../upgradePopUp/upgradePopUp'
import addAcademicImage from 'Assets/AcademicModule/addAcademic.png';
import AcademicYears from '../AcademicYearsComponent/AcademicYears';
import addAcademicIcon from 'Assets/AcademicModule/plus.png';
import Modal from 'react-bootstrap/Modal';
import crossIcon from 'Assets/AcademicModule/cross.png';
import { getImage, getProfileImage } from "Config/commonFirebaseImage";
import InputMask from 'react-input-mask';
import DivLoader from 'Components/LoadingComponent/DivLoader';
import './AcademicDashboard.scss';
import {  setRosterState } from 'Actions/RosterAction/rosterAction';
import { setClassState } from 'Actions/ClassAction/classAction';
import { createNotification } from 'Config/notificationtoast';
import * as routes from "Router/RoutesURL";
import { getSharingCondition, logOut } from 'Utils/Helper';

const AcademicDashboard = (props) => {
    const {
        history,
        loader,
        fetching,
        academicData,
        academicYears,
        getAcademicList,
        getAcademicScore,
        getAcademicYears,
        addAcademicYears,
        deleteAcademicYears,
        editAcademicYears,
        getRosterClassByAcademicYear,
        getUserSettingRequest,
        academicRosterClassListData
    } = props;
    let data = JSON.parse(sessionStorage.getItem('UserData'))

    const [show, setShow] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [name, setName] = useState(data?.cc_user_first_name);
    const [lastName, setLastName] = useState(data?.cc_user_last_name);
    const [Userprofile, setUserprofile] = useState(data?.cc_user_pic);
    const [id, setId] = useState(data?.cc_user_email_id);
    const [userId, setUserId] = useState(data?.cc_user_id);
    const [persent, setPersent] = useState('0%');
    const [valfordlt, setvalfordlt] = useState(false);
    const [deleteYearId, setDeleteYearId] = useState('');
    const [deleteYearIndex, setDeleteYearIndex] = useState('');
    const [editOpen, setEditOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);
    const [addYear, setAddYear] = useState('');
    const [academic_YearsList, setAcademic_YearsList] = useState([]);
    const [academic_AllData, setAcademic_AllData] = useState([]);
    const [academicListData, setAcademicListData] = useState([]);
    const [academicScoreData, setAcademicScoreData] = useState('');
    const [isEditYearId, setIsEditYearId] = useState('');
    const [isEditYearNewValue, setIsEditYearNewValue] = useState('');
    const [isDeleteYearIndex, setIsDeleteYearIndex] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [isAdded, setIsAdded] = useState(false);
    const [LoaderStatus, setLoaderStatus] = useState(true);
    const [errorMessageformanually, setErrorMessageformanually] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [academicRosterClassData, setAcademicRosterClassData] = useState([]);
    const [isSelected, setIsSelected] = useState(0);
    const [selected_academic_Year, setSelected_academic_Year] = useState(0);
    const [selectedAcademicYearData, setSelectedAcademicYearData] = useState([]);
    const [rosterDataFinal, setRosterDataFinal] = useState([]);
    const [userProfileName, setUserProfileName] = useState('');
    const [filteredAcademicYearList, setFilteredAcademicYearList] = useState([]);
    const [userChangedAcdemicYear, setUSerChangedAcademicYear] = useState(false)
    useEffect(() => {
        if (loader === false) {
          const timeoutId = setTimeout(() => {
            sessionStorage.setItem("backpage2", "Aca_year");
          }, 3000);
          return () => clearTimeout(timeoutId);
        }
      }, [loader])
    const loaded = useSelector((state) => state.academic.loaded)
    const d = new Date();
    var currentYear = d.getFullYear();
    var currentMonth = d.getMonth();

    const dispatch = useDispatch()
    useEffect(() => {
        if(sessionStorage.getItem('userHasFreePlan') == '0') {
            history.push(routes.UPGRADEPLAN)
        } else {
        // setUserIdOnFirebase();
        getAcademicYears(); // for popup (academic_Years master data)
        getAcademicScore();
        getAcademicList(); // for user academic data (academic_Data)
        getUserSettingRequest()
        }

    }, [getAcademicScore, getAcademicList, getUserSettingRequest, getAcademicYears])

    // useEffect(() => {
    //     setAcademicScoreData({
    //         total_score: 50,
    //         maximum_score : 100
    //     })
    // },[])

      useEffect(() => {
        if(sessionStorage.getItem('userHasFreePlan') == '0') {
            history.push(routes.UPGRADEPLAN)
        } else {
    if(sessionStorage.getItem('previousPage') == 'login') {
        createNotification('success', "Welcome Back!" , { autoClose: 5000 });
    }
    setTimeout(() => {
       sessionStorage.removeItem('previousPage')     
    }, 6000);
}
  }, []);
  
    useEffect(() => {
        if (academicListData.length > 0) {
            let tempArr = []
            academicListData && academicListData.filter((item, index) => {
                // item.academic_year.includes(currentYear)
                if (item.academic_year.includes(currentYear)) {
                    tempArr.push(item)
                }
            }
            )
            setFilteredAcademicYearList(tempArr);
        }
    }, [academicListData])

    useEffect(() => {
        
        let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
        let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
        
        var api_data = {
            "academic_year_id": localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id,
            "roster_limit": "",
            "class_limit": "",
            "blank_class": "",
        }
        if ((localAcademicYear !== null || academicYearData !== undefined) && sessionStorage.getItem('previousPage') != 'login') {
            getRosterClassByAcademicYear(api_data);
        } else {
            getAcademicList();
        }
    }, [getRosterClassByAcademicYear])

    useEffect(() => {
        const getUserProfile = async () => {
            let data = JSON.parse(sessionStorage.getItem('UserData'))
            let value = ''
            if (data?.cc_user_pic && data?.cc_user_pic.includes("https") === true) {
                setUserprofile(data?.cc_user_pic);
                setUserProfileName(data?.cc_user_pic);
            } else {
                if (data?.cc_user_pic === '') {
                    setUserprofile('');
                    setUserProfileName('');
                }
                else {
                    value = await getProfileImage(data?.cc_user_pic);
                    setUserprofile(value);
                    setUserProfileName(data.cc_user_pic);
                }
            }
        }

        getUserProfile()
            .catch(console.error);
    }, [data])

    useEffect(() => {
        //----- Get Academic Years Master List -----//
        if (academicData) {
            if (academicData?.action === 'GET_ACADEMIC_YEARS_SUCCESS') {
                if (academicData?.academic_Years !== null) {
                    // this.onButtonClick();
                    setAcademic_YearsList(academicData.academic_Years.data)
                    // window.location.reload();
                }
            }
        }
        //----- Get Academic Years Master List -----//

        //----- Add User Academic Year Success -----//
        if (academicData) {
            if (academicData?.action === 'ADD_ACADEMIC_YEARS_SUCCESS') {
                let listOfAcademicYear = academicListData
                if (!isAdded) {
                    let newList = []
                    newList.push(academicData?.add_Academic_Years?.response_data)
                    for (let item of listOfAcademicYear) {
                        newList.push(item)
                    }
                    setIsAdded(true)
                }
            }
        }
        //----- Add User Academic Year Success -----//

        //----- Edit User Academic Year Success -----//
        if (academicData) {
            if (academicData?.action === 'EDIT_ACADEMIC_YEARS_SUCCESS') {
                let listOfAcademicYear = academicListData
                // const isEditYearId = isEditYearId;
                // const isEditYearNewValue = isEditYearNewValue;
                for (let item of listOfAcademicYear) {
                    if (item.academic_year_id === isEditYearId) {
                        item.academic_year = isEditYearNewValue
                    }
                }

        //         const mostRecentIndex = listOfAcademicYear?.reduce((latestIndex, currentItem, index, array) => {
        //             if (new Date(currentItem?.academic_modified_on) > new Date(array[latestIndex]?.academic_add_on)) {
        //                 return index;
        //             }
        //             return latestIndex;
        //         }, 0);
        //         // if (sessionStorage.getItem("backpage2")) {
        //         //     alert("NNN")
        //         // }
        //         const selectedAcademicYear = listOfAcademicYear?.map((item, idx) => ({
        //         ...item,
        //             isSelectedYear: idx === mostRecentIndex,
        //         }));

        //         setAcademicListData(selectedAcademicYear);
        //         let data = selectedAcademicYear?.find((item) => item.isSelectedYear === true);
        //         setSelectedAcademicYearData([data])
        //         let index = selectedAcademicYear?.findIndex(item => item.isSelectedYear === true);
        //         sessionStorage.setItem("isSelectedYear", index);
        //         sessionStorage.setItem("selectedAcademicYear", data?.academic_year_id);
        //         sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(data));
        //         setSelected_academic_Year(data?.academic_year_id);
        //         console.log('object :>> ');
        //         setIsEditYearId('');
        //         setIsEditYearNewValue('');
            }
        }
        //----- Edit User Academic Year Success -----//

        //----- Delete User Academic Year -----//
        if (academicData) {

            if (academicData?.action === 'DELETE_ACADEMIC_YEARS_SUCCESS') {
                setvalfordlt(true)
                let listOfAcademicYear = academicListData
                const Index = isDeleteYearIndex
                // console.log('state1111111111111 :>> ', state1);
                if (!isDeleted) {
                    listOfAcademicYear.splice(Index, 1)
                    // setAcademicListData(listOfAcademicYear);
                    setIsDeleteYearIndex('');
                    setIsDeleted(true);
                    const mostRecentIndex = academicListData.reduce((latestIndex, currentItem, index, array) => {
                        if (new Date(currentItem.academic_add_on) > new Date(array[latestIndex].academic_add_on)) {
                            return index;
                        }
                        return latestIndex;
                    }, 0);
                    const selectedAcademicYear = academicListData.map((item, index) => ({
                        ...item,
                        isSelectedYear: index === 0 ? true : false,
                    }));

                    // if(selectedAcademicYear.length > 0) {
                    //     setAcademicListData(selectedAcademicYear)
                    //     setIsSelected(0);
                    //     setSelected_academic_Year(selectedAcademicYear[0].academic_year_id);
                    //     setSelectedAcademicYearData([selectedAcademicYear[0]])
                    //     sessionStorage.setItem("isSelectedYear", 0)
                    //     sessionStorage.setItem("selectedAcademicYear", selectedAcademicYear[0].academic_year_id)
                    //     sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(selectedAcademicYear[0]))
                    // } else {
                    //     sessionStorage.removeItem("selectedAcademicYear")
                    //     sessionStorage.removeItem("isSelectedYear")
                    //     sessionStorage.removeItem("selectedAcademicYearData")

                    //     setAcademicListData([])
                    //     setIsSelected(0);
                    //     setSelected_academic_Year(0);
                    //     setSelectedAcademicYearData([])
                    // }

                }
            }
        }
        //----- Delete User Academic Year -----//

        //----- Add/Edit User Academic Year Failure -----//
        if (academicData) {
            if (academicData?.action === 'ADD_ACADEMIC_YEARS_FAILURE' || 'EDIT_ACADEMIC_YEARS_FAILURE') {
                if (academicData?.error &&
                    (academicData?.error?.message === 'Note limit has been exceeded. Please upgrade the plan'
                        || academicData?.error?.message === "Media limit has been exceeded. Please upgrade the plan"
                        || academicData?.error?.message === "Please update your subscription plan")) {
                    setShowPopUp(true);
                }
            }
        }
        //----- Add/Edit User Academic Year Failure -----//

        //-----Get User Academic Score -----//
        if (academicData) {
            if (academicData?.action === 'GET_ACADEMIC_SCORE_SUCCESS') {
                if (academicData?.academic_Score !== null) {
                    sessionStorage.setItem("quality_note", JSON.stringify(academicData?.academic_Score?.data?.note_quality_details))
                    setAcademicScoreData(academicData.academic_Score.data);
                }
            }
        }
        //-----Get User Academic Score -----//

        //-----Get User Academic Years List -----//
        const fetchAcademicYearData = async () => {
            
            if (academicData) {
                if (academicData?.action === 'GET_ACADEMIC_LIST_SUCCESS') {
                    if (academicData?.academic_Data && academicData?.academic_Data?.data?.response_data?.length > 0) {
                        setLoaderStatus(academicData.loader);
                        // setSelected_academic_Year(academicData.academic_Data.data.response_data[0].academic_year_id);
                        // setAcademicRosterClassData(academicData.academic_Data.data.response_data[0]);
                        let academicData2 = academicData?.academic_Data?.data?.response_data;
                        if(academicData2.length === 1){
                            const selectedAcademicYear = academicData2.map((item) => ({
                                ...item,
                                isSelectedYear: true,
                              }));
                              setAcademicListData(selectedAcademicYear)
                              setSelectedAcademicYearData(selectedAcademicYear)

                                sessionStorage.setItem("isSelectedYear", 0)
                                sessionStorage.setItem("selectedAcademicYear", selectedAcademicYear[0].academic_year_id)
                                sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(selectedAcademicYear[0]))
                                setSelected_academic_Year(selectedAcademicYear[0].academic_year_id);

                            } else if (academicData2.length > 1) {
                                // if (valfordlt == false) {
                                    if (sessionStorage.getItem("backpage2") !== "Aca_year") {
                                        // const local = JSON.parse(sessionStorage.getItem("selectedAcademicYearData"));
                                        const selectedAcademicYear = academicData2.map((item, idx) => ({
                                            ...item,
                                            isSelectedYear: valfordlt == false ? (sessionStorage.getItem("selectedAcademicYear") ? (item?.academic_year_id == sessionStorage.getItem("selectedAcademicYear")) : idx == 0) : idx == 0,
            
                                        }));
                                            setAcademicListData(selectedAcademicYear)
                                            let data = selectedAcademicYear.find((item) => item.isSelectedYear === true);
                                            setSelectedAcademicYearData([data])
                                            let index = selectedAcademicYear.findIndex(item => item.isSelectedYear === true);
                                            sessionStorage.setItem("isSelectedYear", index);
                                            sessionStorage.setItem("selectedAcademicYear", data?.academic_year_id);
                                            sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(data));
                                            setSelected_academic_Year(data?.academic_year_id);
                                            // console.log('data?.academic_year_id :>> ', data?.academic_year_id);
                                    }
                                    else {
                                        const mostRecentIndex = academicData2.reduce((latestIndex, currentItem, index, array) => {
                                            const currentDate = new Date(currentItem?.academic_modified_on || currentItem?.academic_add_on);
                                            const latestDate = new Date(array[latestIndex]?.academic_modified_on || array[latestIndex]?.academic_add_on);
                                        
                                            if (currentDate > latestDate) {
                                                return index;
                                            }
                                            return latestIndex;
                                        }, 0);
                                        
                                        const selectedAcademicYear = academicData2?.map((item, idx) => ({
                                            ...item,
                                            isSelectedYear: valfordlt == false ?  idx === mostRecentIndex : idx == 0,
                                        }));
                                        
    
                                                setAcademicListData(selectedAcademicYear);
                                                let data = selectedAcademicYear.find((item) => item.isSelectedYear === true);
                                                setSelectedAcademicYearData([data])
                                                let index = selectedAcademicYear.findIndex(item => item.isSelectedYear === true);
                                                sessionStorage.setItem("isSelectedYear", index);
                                                sessionStorage.setItem("selectedAcademicYear", data?.academic_year_id);
                                                sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(data));
                                                setSelected_academic_Year(data?.academic_year_id);
                                                // console.log('data?.academic_year_id :>> ', data?.academic_year_id);
                                    }
                                // }
                               
                               

                              
                            }
                            
                        // ---Fatch Firebase Image---
                        if (academicData2) {
                            for (let academicItem of academicData2) {
                                if (academicItem.roster_data) {
                                    let roster_data = academicItem.roster_data
                                    for (let rosterItem of roster_data) {
                                        if (rosterItem.cc_student_pic_url) {
                                            if (!(rosterItem.cc_student_pic_url.includes("https") === true)) {
                                                rosterItem.cc_student_pic_url = await getImage(rosterItem.cc_student_pic_url);
                                            }

                                        }
                                    }
                                }
                            }
                        }

                        // setAcademicListData(academicData2);
                        // this.setState({academicRosterClassData: nextProps.academicRosterClassData.response_data})
                    }
                }

                if (academicData?.error) {
                    if (academicData.error.message === 'Your session has been expired. Please login again') {

                    }
                }
            }
        }
        //-----Get User Academic Years List -----//

        if (academicRosterClassListData) {
            if (academicRosterClassListData?.status === 200) {
                // if (academicRosterClassListData?.message === "Academic Year Roster and Class List : ") {
                    setAcademicRosterClassData(academicRosterClassListData.data)
                // }
            }
        } 
        else {
            if (academicData && !userChangedAcdemicYear) {
                if (academicData?.academic_Data?.status === 200) {
                    // if (academicData?.academic_Data?.message === "Total user academic year list") {
                        setAcademicRosterClassData(academicData.academic_Data.data.response_data[0])
                    // }
                }
            }
        }
            fetchAcademicYearData()
            .catch(console.error);
    }, [academicData, academicRosterClassData, academicYears, academicRosterClassListData])
    // ---------Get Image From Firebase--------

    const editYearPopover = (index) => {
        setEditOpen(!editOpen);
        // if (index !== undefined) {
        //     let academicListValue = academicListData;
        //     // academicListData[index].editOpen = !academicListData[index].editOpen;
        //     setAcademicListData(academicListValue)
        //     // setShowPopUp(true);

        // }
    }

    const addYearPopover = () => {
        setvalfordlt(false)
        setAddNew(!addNew)
    }

    const handleClosePopUp = (e) => {
        setShowPopUp(false);
    }

    const editYearbyInput = (item) => {
        setEditOpen(!editOpen)
        if (addYear?.length === 9) {
            let yearValue = addYear
            let stringArray = yearValue.split("-");
            if (stringArray[0] < stringArray[1]) {
                yearValue = stringArray[0] + ' - ' + stringArray[1];
                callEditYear(item, yearValue)
                return false;
            } else {
                setErrorMessageformanually('Please enter valid year')
                return false;
            }
        } else {
            if (addYear?.length === 5) {
                let yearValue = addYear
                let stringArray = yearValue.split(" ");
                yearValue = stringArray[0];
                callEditYear(item, yearValue)
                return false;
            } else {
                setErrorMessageformanually('Please enter valid year')
                return false;
            }
        }
    }

    const callEditYear = (item, addYear) => {
        // let academicListData = academicListData;
        sessionStorage.setItem('addYear',addYear)
        sessionStorage.setItem('currentYearId',item?.academic_year_id)
        if(sessionStorage.getItem('UserInitialLogin')) {
            let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
            let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
            
            var api_data = {
                "academic_year_id": localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id,
                "roster_limit": "",
                "class_limit": "",
                "blank_class": "",
                "userInitialLogin": sessionStorage.getItem('UserInitialLogin'),
                "editCurrentYear": true
            }
            // console.log("second")
            getRosterClassByAcademicYear(api_data);
            academicListData.editOpen = !academicListData.editOpen;
        } else {
            let isShared = getSharingCondition((academicData?.academic_Roster?.data?.roster_data || academicData?.academic_Data?.data?.response_data?.[0]?.roster_data), 
            (academicData?.academic_Roster?.data?.class_data || academicData?.academic_Data?.data?.response_data?.[0]?.class_data))
            
            if(isShared) {
                createNotification('error',  'You cannot edit the Academic Year containing shared Classes or Student'); 
                academicListData.editOpen = !academicListData.editOpen;
            } else {
        const data = {
            academic_year_id: item.academic_year_id,
            acadmic_year: addYear,
        }
        
        setIsEditYearId(item);
        setIsEditYearNewValue(addYear);
        academicListData.editOpen = !academicListData.editOpen;
        // setAcademicListData(academicListData);
        setAddYear('');
        editAcademicYears(data)
    }
    }
    }

    const editYearbyGivenList = (item, value, index) => {
        sessionStorage.setItem('addYear',item?.cc_academic_year)
        sessionStorage.setItem('currentYearId',value?.academic_year_id)
        if(sessionStorage.getItem('UserInitialLogin')) {
            let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
            let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
            
            var api_data = {
                "academic_year_id": (localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id),
                "roster_limit": "",
                "class_limit": "",
                "blank_class": "",
                "userInitialLogin": sessionStorage.getItem('UserInitialLogin'),
                "editCurrentYear": true
            }
            // console.log("third")
            getRosterClassByAcademicYear(api_data);
            setEditOpen(!editOpen);
            const data = {
                academic_year_id: value.academic_year_id,
                acadmic_year: item.cc_academic_year
            }
            setIsEditYearId(value.academic_year_id);
            setIsEditYearNewValue(item.cc_academic_year);

            // academicListData[index].editOpen = !academicListData[index].editOpen;
            setEditOpen(!editOpen);
            // setAcademicListData(academicListData)
            setAddYear('');
            props.editAcademicYears(data)
        } else {
            let isShared = getSharingCondition((academicData?.academic_Roster?.data?.roster_data || academicData?.academic_Data?.data?.response_data?.[0]?.roster_data), 
            (academicData?.academic_Roster?.data?.class_data || academicData?.academic_Data?.data?.response_data?.[0]?.class_data))
            
            if(isShared) {
                createNotification('error',  'You cannot edit the Academic Year containing shared Classes or Student'); 
                setEditOpen(!editOpen);
            } else {
        if (!(value?.is_already_exist)) {
            // let academicListData = academicListData;
            const data = {
                academic_year_id: value.academic_year_id,
                acadmic_year: item.cc_academic_year
            }
            setIsEditYearId(value.academic_year_id);
            setIsEditYearNewValue(item.cc_academic_year);

            // academicListData[index].editOpen = !academicListData[index].editOpen;
            setEditOpen(!editOpen);
            // setAcademicListData(academicListData)
            setAddYear('');
            props.editAcademicYears(data)
            // this.props.getAcademicList(data);
        }
    }
    }
    }

    const handleChangeYear = event => {
        setErrorMessageformanually('');
        dispatch(setRosterState());
        dispatch(setClassState());
        // window.sessionStorage.removeItem('UserInitialLogin')
        // setAcademicRosterClassData([]);
        // dispatch(setRosterState())
        // [event.target.name]: event.target.value,
    };

    const getUserData = () => {
        let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData : academicListData[0]
        var api_data = {
            "academic_year_id": selected_academic_Year,
            "roster_limit": "",
            "class_limit": "",
            "blank_class": "",
        }
        if (academicYearData !== undefined) {
            // console.log("fourth")
            getRosterClassByAcademicYear(api_data);
        } else {
            getAcademicList();
        }
    }
    useEffect(() => {
        let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
            let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
            var api_data = {
                "academic_year_id": localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id,
                "roster_limit": "",
                "class_limit": "",
                "blank_class": "",
            }
            // console.log('new222 :>> ', api_data , localAcademicYear , academicYearData?.academic_year_id);
            if (localAcademicYear !== null && academicYearData !== undefined && academicYearData?.academic_year_id) {
                // console.log("fifth")
                getRosterClassByAcademicYear(api_data);
            } else {
                getAcademicList();
            }
    }, [selected_academic_Year])

    
    
    
    
    const addNewAcademicYear = (item, value) => {
        // console.log('item :>> ', item , value);
        window.sessionStorage.removeItem('UserInitialLogin')
        dispatch(setRosterState())
        dispatch(setClassState())
        if (!(item?.is_already_exist)) {
            const data = { acadmic_year: value }
            setAddNew(false);
            setIsAdded(false);
            addAcademicYears(data);
            getAcademicYears(data); //for master list

            let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
            let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
            var api_data = {
                "academic_year_id": localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id,
                "roster_limit": "",
                "class_limit": "",
                "blank_class": "",
            }
            // console.log('new :>> ', api_data , localAcademicYear , academicYearData?.academic_year_id);
            if (localAcademicYear !== null || academicYearData !== undefined) {
                // getRosterClassByAcademicYear(api_data);
            } else {
                getAcademicList();
            }
        }
    }

    const deleteAcademicDetails = (value, index) => {
        const data = { academic_year_id: value, index: index, academicListData: academicListData }
        //CHECK FOR SHARED CLASSES AND ROSTER
        
    //    console.log('data :>> ', data);
        if(sessionStorage.getItem('UserInitialLogin')) {
            let localAcademicYear = sessionStorage.getItem("selectedAcademicYear")
            let academicYearData = selectedAcademicYearData?.length > 0 ? selectedAcademicYearData[0] : academicListData[0]
            
            var api_data = {
                "academic_year_id": selected_academic_Year ? selected_academic_Year : localAcademicYear ? localAcademicYear : academicYearData?.academic_year_id,
                "roster_limit": "",
                "class_limit": "",
                "blank_class": "",
                "userInitialLogin": sessionStorage.getItem('UserInitialLogin')
            }
            // console.log("sixth")
            deleteAcademicYears(api_data)
            getRosterClassByAcademicYear(api_data);
            setIsDeleted(false);
            setShow(false);
        } else {
            let isShared = getSharingCondition((academicData?.academic_Roster?.data?.roster_data || academicData?.academic_Data?.data?.response_data?.[0]?.roster_data), 
            (academicData?.academic_Roster?.data?.class_data || academicData?.academic_Data?.data?.response_data?.[0]?.class_data))
            
                if(isShared) {
                    createNotification('error',  'You cannot delete the Academic Year containing shared Classes or Student'); 
                    setIsDeleted(false);  
                     setShow(false);
                } else {
                        
                    var api_data = {
                        // "academic_year_id": index === 0 ? academicListData?.[1]?.academic_year_id : academicListData?.[0]?.academic_year_id,
                        "academic_year_id": sessionStorage.getItem("selectedAcademicYear") || selected_academic_Year,
                        "roster_limit": "",
                        "class_limit": "",
                        "blank_class": "",
                    }
                    setIsDeleteYearIndex(index);
                    setIsDeleted(false);
                    setShow(false);
                    deleteAcademicYears(data)
                    // getAcademicList(data);

                    // if (value !== undefined && academicListData.length > 0) {
                    //     console.log('seventh');
                    //     getRosterClassByAcademicYear(api_data);
                    // }
                    // const selectedAcademicYear = academicListData.map((item, index) => ({
                    //     ...item,
                    //     isSelectedYear: index === 0 ? true : false,
                    // }));
                    //   console.log('academicListData :>> ', academicListData);
                    // setAcademicListData(selectedAcademicYear)
                    setIsSelected(0);
                    // setSelected_academic_Year(selectedAcademicYear[0].academic_year_id);
                    // setSelectedAcademicYearData([selectedAcademicYear[0]])
                    sessionStorage.setItem("isSelectedYear", 0)
                    // sessionStorage.setItem("selectedAcademicYear", selectedAcademicYear[0].academic_year_id)
                    // sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(selectedAcademicYear[0]))

                        // sessionStorage.setItem("isSelectedYear")
                        // setSelectedAcademicYearData([])
                        // setAcademicListData([])
                        // setAcademicRosterClassData([])
                        // setRosterDataFinal([])
                        // setSelected_academic_Year(0)
                        // setSelectedAcademicYearData([])
                }
        }
    }

  const changeAcademicYear = (index, value) => {
    setvalfordlt(false);

    sessionStorage.removeItem('UserInitialLogin');
    dispatch(setRosterState());
    dispatch(setClassState());
    setAcademicRosterClassData([]);
    setUSerChangedAcademicYear(true);

    // Fetch the academic year using the index
    const selectedYear = value[index];

    // Ensure that the selected year exists before proceeding
    if (selectedYear) {
        var api_data = {
            "academic_year_id": selectedYear.academic_year_id,
            "roster_limit": "",
            "class_limit": "",
            "blank_class": "",
        };

        getRosterClassByAcademicYear(api_data);

        
        // Map through the academicListData and update the isSelectedYear flag
        const updatedAcademicListData = academicListData.map((item) => ({
            ...item,
            isSelectedYear: item.academic_year_id === selectedYear.academic_year_id ? true : false,
        }));
        
        setAcademicListData(updatedAcademicListData);

        // Find the selected academic year data from the updated list
        let data = updatedAcademicListData.find((item) => item.isSelectedYear === true);
        setSelectedAcademicYearData([data]);

        // Save the selected year details in sessionStorage
        sessionStorage.setItem("isSelectedYear", index);
        sessionStorage.setItem("selectedAcademicYear", selectedYear.academic_year_id);
        sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(data));

        // Update the local state with the selected year and index
        setIsSelected(index);
        setSelected_academic_Year(selectedYear.academic_year_id);
    } else {
        console.error('No academic year found for the given index:', index);
    }
};

    // const changeAcademicYear = (index, value) => {
    //     setvalfordlt(false)
    //     console.log('academicRosterClassData :>> ', academicRosterClassData);
    //     console.log('index, value :>> ', index, value);
    //     sessionStorage.removeItem('UserInitialLogin')
    //     dispatch(setRosterState());
    //     dispatch(setClassState());
    //     setAcademicRosterClassData([])
    //     setUSerChangedAcademicYear(true)
    //     var api_data = {
    //         "academic_year_id": value[index].academic_year_id,
    //         "roster_limit": "",
    //         "class_limit": "",
    //         "blank_class": "",
    //     }
    //     console.log('api_data 7777777777 :>> ', api_data);
    //     if (value !== undefined) {
    //         // console.log("eitght")
    //         getRosterClassByAcademicYear(api_data);
    //     }
    //     console.log('academicListData 777777 :>> ', academicListData);
    //     const selectedAcademicYear = academicListData.map((item) => ({
    //         ...item,
    //         isSelectedYear: item.academic_year_id === value[index].academic_year_id ? true : false,
    //         }));
    //         setAcademicListData(selectedAcademicYear)

    //         let data = selectedAcademicYear.find((item) => item.isSelectedYear === true);
    //         setSelectedAcademicYearData([data])

    //         sessionStorage.setItem("isSelectedYear", index)
    //         console.log("isSelectedYear 77777", index)
    //         sessionStorage.setItem("selectedAcademicYear", value[index].academic_year_id)
    //         console.log('selectedAcademicYear 77777 :>> ', selectedAcademicYear);
    //         sessionStorage.setItem("selectedAcademicYearData", JSON.stringify(data))

    //         setIsSelected(index);
    //         setSelected_academic_Year(value[index].academic_year_id);
    //     }


    const handleClose = () => {
        setShow(false);
    }

    const handleShow = (academic_year_id, index) => {
        
            let isShared = getSharingCondition((academicData?.academic_Roster?.data?.roster_data || academicData?.academic_Data?.data?.response_data?.[0]?.roster_data), 
            (academicData?.academic_Roster?.data?.class_data || academicData?.academic_Data?.data?.response_data?.[0]?.class_data))
            // if(isShared) {
            //     createNotification('error',  'You cannot delete the Academic Year containing shared Classes or Student');
            // } else {
                setShow(true);
                setDeleteYearId(academic_year_id);
                setDeleteYearIndex(index);
            // }
    }

    let academicYearData = selectedAcademicYearData && selectedAcademicYearData[0]?.academic_year_id ? selectedAcademicYearData[0] : academicListData[0]

     const getProgressBarGradient = () => {
    if ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 75) {
      return 'linear-gradient(to right, #FD1010, #F96017, #F6AD1E, #40CC3B)';
    } else if ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 50) {
      return 'linear-gradient(to right, #FD1010, #F96017)';
    } else if ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 25) {
      return 'linear-gradient(to right, #FD1010, #F96017)';
    } else {
      return 'repeating-linear-gradient(45deg, #FD1010, transparent 101px)';
    }
  };

     const progressBarStyle = {
    width: `${(academicScoreData.total_score / academicScoreData.maximum_score) * 100}%`,
    background: `${getProgressBarGradient()}`
  };

    return (
        <div className='academicMainContainer'>
            {/* <span data-testid = "addN" className='addButtonText' style={{ marginLeft: addNew ? 0 : "0.3rem" }} >{addNew ? 'Cancel' : 'Year'}</span> */}
            <p style={{display : "none"}} data-testid = "truebtn"
                    onClick={() => {
                        setAcademicListData([
                            {
                                "academic_year_id": 791,
                                "created_by": 508,
                                "academic_year": "2025 - 2026",
                                "academic_add_on": "2022-12-05T07:37:07.000Z",
                                "academic_modified_on": "2022-12-05T15:22:19.000Z",
                                "school_id": null,
                                "user_type": "teacher",
                                "current_year": null,
                                "is_active": 1,
                                "is_delete": 0,
                                "year_ids": "791,883,884,910,911",
                                "associate_user_ids": "508,508,508,508,508",
                                "academic_user_id": 508,
                         
                                "class_count": 14,
                                "roster_count": 133,
                                "isSelectedYear": true
                            },
                       
                        ]);
                        setAcademicScoreData({
                            "display_text": "Total Score",
                            "maximum_score": 1060,
                            "total_score": 146,
                            "text_points": 122,
                            "image_points": 24,
                            "video_points": 0,
                            "audio_points": 2,
                            "user_notes_count": 106,
                            "note_quality_details": {
                                "image_allotted_points": "2",
                                "audio_allotted_points": "2",
                                "video_allotted_points": "2",
                                "text_allotted_points": "1",
                                "max_score": "10",
                                "max_allotted_text": "3",
                                "max_note_quality": "10",
                                "avg_note_quality": "8",
                                "min_note_quality": "5",
                                "max_note_quality_text": "Outstanding",
                                "avg_note_quality_text": "Commendable",
                                "below_avg_note_quality_text": "Met Expectation",
                                "min_note_quality_text": "Need improvement",
                                "below_min_note_quality_text": "Note Quality"
                            }
                        });
                    }}
            ></p>
            {/* ==========Profile/Add/Progress Section===== */}
            <div className='progressBarDiv'>
                {/* ==========Profile/Add/Progress show Section===== */}
                <div className="addNewSectionMain">
                    {/* ==========Profile Section===== */}
                    <div className="profileSectionCol">
                        <div className='profileDiv'>
                            {Userprofile === '' ?
                                <div className='imageDummyDivProfile cursor-pointer' onClick={() => history.push(routes.USERPROFILE)}>
                                    <h5 className='profileImageDummyText'>{name.charAt(0)}{lastName.charAt(0)}</h5>
                                </div> :
                                <img src={Userprofile} alt='' className='profileImage cursor-pointer' onClick={() => history.push(routes.USERPROFILE)}/>}
                            <div className='profileNameDiv cursor-pointer' onClick={() => history.push(routes.USERPROFILE)}>
                                <p className='profileName'>Hello, {name?.replace(/(.{13})..+/, "$1…")} </p>
                            </div>
                        </div>
                    </div>
                    {/* ==========Profile Section===== */}

                    {/* ==========Progress Section===== */}
                    {academicListData?.length > 0 && academicScoreData && <div className="progressSectionCol">
                        <div className='progressScoreMainDiv'>
                            <div className='sectionSeprator'></div>
                            <div className='progressScoreSubDiv'>
                                <div className='progressScoreDiv'>
                                    <div className='progressbarScoreText'>
                                        {academicScoreData && <p className='totalScore'>Total Power Points : <span 
                                        style={{ color: (academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 75 ? "#40CC3B" : 
                                        (academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 50 ? '#F6AD1E' :
                                        (academicScoreData.total_score / academicScoreData.maximum_score) * 100 >= 25 ? "#F96017" :
                                        '#FD1010' }}
                                        >{academicScoreData.total_score}</span></p>}
                                    </div>

                                </div>
                                <div className="progress">
                                    {academicScoreData.maximum_score < 1 ?
                                        <div className="progress-bar progressbar progressbarfirst" style={{ width: '0%' }} aria-valuemin='100' aria-valuemax='100'></div>
                                        : 
                                        <>
                                        <div className="progress-bar" 
                                        style={progressBarStyle}
                                        // style={{width  :"10%", background  :"repeating-linear-gradient(45deg, red, transparent 101px)"}}
                                        ></div>
                                        {/* <div className="progress-bar progressbar progressbarfirst" style={{ width: (academicScoreData.total_score / academicScoreData.maximum_score) * 100 + '%' }} aria-valuemin={academicScoreData.total_score} aria-valuemax='100'></div> */}
                                        {/* <Progress multi>
                                            {
                                                (academicScoreData.total_score / academicScoreData.maximum_score) * 100 <= '25' &&  
                                                <Progress bar color="danger" value={`${(academicScoreData.total_score / academicScoreData.maximum_score) * 100}`}></Progress>
                                            }
                                             {
                                                ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 <= '50' && 
                                                (academicScoreData.total_score / academicScoreData.maximum_score) * 100 > '25')
                                                 && <Progress bar color="warning" value={`${(academicScoreData.total_score / academicScoreData.maximum_score) * 100}`}></Progress>
                                            }
                                             {
                                                 ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 <= '75' && 
                                                 (academicScoreData.total_score / academicScoreData.maximum_score) * 100 > '50')
                                                 &&  <Progress bar color="info" value={`${(academicScoreData.total_score / academicScoreData.maximum_score) * 100}`}></Progress>
                                            }
                                             {
                                                 ((academicScoreData.total_score / academicScoreData.maximum_score) * 100 <= '100' && 
                                                 (academicScoreData.total_score / academicScoreData.maximum_score) * 100 > '75')
                                                 &&  <Progress bar color="success" value={`${(academicScoreData.total_score / academicScoreData.maximum_score) * 100}`}></Progress>
                                            }
      </Progress> */}
                                        </>
                                        }

                                </div>
                                
                                <div className='progressScoreDiv'>
                                    <div className='progressbarScoreText'>
                                        {academicScoreData.maximum_score > 0 && <p className='score_0'>0</p>}
                                    </div>
                                    <div className='progressbarNoteText'>
                                        <p className='score_500'>{academicScoreData.maximum_score}</p>
                                    </div>
                                </div>
                                {/* <div className="progress-bar progressbar progressbarfirst" style={{ width: (academicScoreData.total_score / academicScoreData.maximum_score) * 100 + '%' }} aria-valuemin={academicScoreData.total_score} aria-valuemax='100'></div> */}
                            </div>
                        </div>
                    </div>}
                    {/* ==========Progress Section===== */}

                </div>
                {/* ==========Profile/Add/Progress show Section===== */}

                {/* ==========Profile/Add/Progress Hide Section===== */}
                {addNew && academicListData?.length === 0 &&
                    <div className='academicPaddingDiv'>
                        <div className="addNewAcademic">
                            <div className='academicTextDiv'>
                                <div className="vertical-center">
                                    <p className='academicText'>Add Academic Year</p>
                                </div>

                            </div>
                            <div className='academicYearDiv'>
                                {academic_YearsList && academic_YearsList.map((item, index) => (
                                    <div onClick={() => { addNewAcademicYear(item, item.cc_academic_year) }} key={index} className={item.is_already_exist ? 'selectedYear' : 'notSelectedYear'}>
                                        <p className={item.is_already_exist ? 'selectedYearText' : 'notSelectedYearText'}>{item.cc_academic_year}</p>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>}
                {/* ==========Profile/Add/Progress Hide Section===== */}

            </div>
            {/* ==========Profile/Add/Progress Section===== */}
            {loader || fetching ?
             <div>
                <DivLoader />
            </div>
            :
            <>
            {
                // (loader === false &&  !academicData?.academic_Data?.data?.response_data) && 
                // <div>
                //             <DivLoader />
                //         </div>
                ''
            }
            </>
            }
            {/* ==========Academic Years Section===== */}
            {/* {academicRosterClassData &&  */}
            {academicListData?.length > 0 ?
                <div className='academicYearsMainSection'>
                    <div className='academicEditSection'>

                        {/* ==========Add Section===== */}
                        <div className="academicYearDiv">
                            {academicListData && academicListData?.length > 0 &&
                                <div className='academicYearSubDiv' >
                                    {

                             
                                    <Button data-testid = "addNewbtn"  className= {JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? 'addNewButton schltchr' : "addNewButton"} color="primary" id={`mypopover${1}`} onClick={() => JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' : addYearPopover()}  >
                                        <img src={addNew ? crossIcon : addAcademicIcon} alt="" className={addNew ? "closeNewIcon material-icons" : "addNewIcon material-icons"} />
                                        <span data-testid = "addNew" className='addButtonText' style={{ marginLeft: addNew ? 0 : "0.3rem" }} >{addNew ? 'Cancel' : 'Year'}</span>
                                    </Button> 
                                  }
                                    <>
                                        <UncontrolledPopover trigger="legacy"
                                            // placement="auto-end"
                                            placement="bottom"
                                            isOpen={addNew}
                                            target={`mypopover${1}`}
                                            toggle={() => JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? '' :  addYearPopover(1)}
                                            style={{ borderRadius: 20 }}
                                            className='popover academicPopOver'
                                        >
                                            {/* <PopoverHeader>
                                                Add Academic Year
                                            </PopoverHeader> */}
                                            <PopoverBody>
                                                <div className='editInputDiv'>
                                                    <div className='popoverList'>
                                                        {academic_YearsList && academic_YearsList.map((item, index) => (
                                                            <div onClick={() => { addNewAcademicYear(item, item.cc_academic_year) }} key={index} className={!item.is_already_exist ? 'popUp-selectedYear cursor-pointer' : 'popUp-notSelectedYear'}>
                                                                <p className={!item.is_already_exist ? 'popUp-selectedYearText' : 'popUp-notSelectedYearText'}>{item.cc_academic_year}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </>
                                    {
                               JSON.parse(sessionStorage.getItem('UserData'))?.user_type != "school_teacher" &&
                                    <div className='classListSeprator'></div>
                                    }
                                    
                                    <div className='divForScroll'>
                                    {academicListData && academicListData.map((item, index) => (


// <div onClick={() => { changeAcademicYear(index, academicListData) }} key={index} className={index === isSelected ? 'selectedYear' : index === 0 && isSelected !== 0 ? 'notSelectedY' : 'notSelectedYear'}>

<div  onClick={() => { changeAcademicYear(index, academicListData) }} key={index} className={item.isSelectedYear === true ? 'selectedYear'
    :
    (item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear - 1 || item.isSelectedYear !== true && item.academic_year.split("-")[1]?.trim() == currentYear + 1 || item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear)
        ? 'notSelectedY' : 'notSelectedYear'}>
    {
        filteredAcademicYearList.length > 1
            ?
            currentMonth <= 5 ?
                <>
                    {item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear - 1 && <span className='current_year'>Current Year</span>}
                </> : <>
                    {item.isSelectedYear !== true && item.academic_year.split("-")[1]?.trim() == currentYear + 1 && <span className='current_year'>Current Year</span>}
                </>
            : filteredAcademicYearList.length == 1 ? <>
                {item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear && <span className='current_year'>Current Year</span>}
            </>
                :
                <span className='current_year'></span>
    }

    <p className={filteredAcademicYearList.length > 1 ? currentMonth <= 5 ? 
     item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear - 1 :
    item.isSelectedYear !== true && item.academic_year.split("-")[1]?.trim() == currentYear + 1 :
    item.isSelectedYear !== true && item.academic_year.split("-")[0]?.trim() == currentYear
    ? 'currentYearNotSelected' : item.isSelectedYear === true ? 'selectedYearText' : 'notSelectedYearText'}>{item.academic_year}</p>
</div>
))}
                                    </div>
                                </div>}
                        </div>
                        {/* ==========Add Section===== */}
                        
                        {academicRosterClassData && academicListData.length > 0 && JSON.parse(sessionStorage.getItem('UserData'))?.user_type != "school_teacher" &&

                            <div className='academicEditDiv'>
                                {/* <div> */}
                                <div id={`mypopover${0}`} data-testid = "editYearMock" className='editDivBlack' onClick={() => editYearPopover(0)}>
                                    <i className="editIcon material-icons">edit</i>
                                    <span className='iconText' style={{marginLeft : 2}}>{editOpen ? 'Cancel' : 'Edit'}</span>
                                </div>

                                <>
                                    <UncontrolledPopover trigger="legacy"
                                        placement="bottom"
                                        isOpen={editOpen}
                                        target={`mypopover${0}`}
                                        toggle={() => editYearPopover(0)}
                                        style={{ borderRadius: 20 }}
                                        className='popover academicPopOver'
                                    >
                                        {/* <PopoverHeader>
                                            Edit Academic Year
                                        </PopoverHeader> */}
                                        <PopoverBody>
                                            <div className='editInputDiv'>
                                                <div className='inputMaskUpdateParentDiv'>
                                                    <div className='inputMaskUpdateDiv'>
                                                        <InputMask
                                                            variant="outlined"
                                                            value={addYear}
                                                            onChange={event => { setAddYear(event.target.value); handleChangeYear() }}
                                                            name="addYear"
                                                            className={`editYearInputUpdateType ${addYear?.length > 5 ? "afterDate" : ""}`}
                                                            // mask="9999 9999"
                                                            mask={addYear?.length > 5 ? "9999-9999" : "9999 9999"}
                                                            maskChar=""
                                                            placeholder="Enter Manually (YYYY-YYYY)" />
                                                        {errorMessageformanually && <p className="errormessageUpdate">{errorMessageformanually}</p>}
                                                    </div>
                                                    {addYear && <Button onClick={() => {
                                                        editYearbyInput(academicYearData)
                                                    }} className='updateButton' >
                                                        <p className='updateButtonText'> Update</p>
                                                    </Button>}
                                                </div>
                                                <div className='popoverList'>
                                                    {academic_YearsList && academic_YearsList.map((item, yearIndex) => (
                                                        <div onClick={() => { editYearbyGivenList(item, academicYearData) }} key={yearIndex} className={!item.is_already_exist ? 'selectedYearPopover cursor-pointer' : 'notSelectedYearPopover'}>
                                                            <p className={!item.is_already_exist ? 'selectedYearTextPopover' : 'notSelectedYearTextPopover'}>{item.cc_academic_year}</p>
                                                        </div>))}
                                                </div>
                                            </div>

                                        </PopoverBody>
                                    </UncontrolledPopover>
                                    <div data-testid = "deleteyearbtn" id= {"mock" + show} onClick={() => { handleShow(selected_academic_Year, isSelected) }} className='deleteDivRed'>
                                        <i className="editIcon material-icons">delete</i>
                                        <span className='iconText'>Delete</span>
                                    </div>
                                </>

                                {/* ==========Academic Year delete Modal===== */}
                                {show &&
                                    <Modal className="deleteAca-modal-warning br-8" show={show} onHide={() => handleClose()} centered={true}>
                                        <Modal.Header>
                                            <Modal.Title>Delete Academic Year</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p className="confirm-text">Are you sure you want to delete <br></br> Academic Year?</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="default" className="cancel-btn" onClick={() => handleClose()}>
                                                Cancel
                                            </Button>
                                            <Button variant="default" className="delete-btn" onClick={() => deleteAcademicDetails(deleteYearId, deleteYearIndex)}>
                                                Delete
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    /* ==========Academic Year delete Modal===== */}
                            </div>
                            
                        }
                    </div>
                </div >
                :
                <>
                {
                    JSON.parse(sessionStorage.getItem('UserData'))?.user_type == "school_teacher" ? 
                    <div className='addAcademicStart'>
                    {loader === false &&
                        <div>
                            <p className='tapToAddText'>Please ask your admin to assign you Academic  Year.</p>
                            {/* <img src={addAcademicImage} alt='' className='addAcademicImage' /> */}
                        </div>
                    }
                </div>
                 :
                 <div className='addAcademicStart'>
                     {loader === false && academicData?.academic_Data?.data?.response_data?.length == 0 &&
                           <div onClick={() => { setAddNew(!addNew) }}>
                             <p className='tapToAddText'>Tap + to launch your Academic Year!</p>
                                <img src={addAcademicImage} alt='' className='addAcademicImage' />
                            </div>
                        }
                    {/* {
                        loader === false &&  !academicData?.academic_Data?.data?.response_data ? 
                        <>
                        <div>
                            <DivLoader />
                        </div>
                        </> 
                        : 
                        <>
                         {loader === false && academicData?.academic_Data?.data?.response_data?.length == 0 &&
                           <div onClick={() => { setAddNew(!addNew) }}>
                             <p className='tapToAddText'>Tap to add academic Year</p>
                                <img src={addAcademicImage} alt='' className='addAcademicImage' />
                            </div>
                        }
                        </>
                    } */}
                
             </div>
                }
               
                </>
            }
            {
                academicRosterClassData && academicListData?.length > 0 &&
                <AcademicYears {...props}
                    yearData={academicYearData}
                    academicRosterClassData={academicRosterClassData}
                    rosterDataFinal={rosterDataFinal}
                    academicYearId={selected_academic_Year}
                    selectedAcademicYearData={selectedAcademicYearData}
                    fetching={fetching}
                    loaded={loaded}
                // getRosterClassByAcademicYear={getRosterClassByAcademicYear}
                // getAcademicList={getAcademicList}
                />
            }
            {showPopUp && <UpgradePopUp handleClosePopUp={() => handleClosePopUp()} />}
            <Modal></Modal>
        </div >
    )
}

const mapStateToProps = state => ({
    state: state,
    academicData: state.academic,
    academicYears: state.academic.academic_Years,
    academicRosterClassListData: state.academic.academic_Roster,
    loader: state.academic.loader,
    fetching: state?.academic?.fetching
});
const mapDispatchToProps = dispatch => {
    return {
        getAcademicList: () => dispatch(getAcademicList()),
        getAcademicScore: () => dispatch(getAcademicScore()),
        getAcademicYears: () => dispatch(getAcademicYears()),
        addAcademicYears: (data) => dispatch(addAcademicYears(data)),
        deleteAcademicYears: (data) => dispatch(deleteAcademicYears(data)),
        editAcademicYears: (data) => dispatch(editAcademicYears(data)),
        getUserSettingRequest: (data) => dispatch(getUserSettingRequest(data)),
        getRosterClassByAcademicYear: (data) => dispatch(getRosterClassByAcademicYear(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    AcademicDashboard
);